/* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid*/

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { StaticQuery, graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import useWindowDimensions from "../components/hooks/useWindowDimensions";
import SocialMediaLinks from "../components/common/socialmedialinks";

import "../styles/index.sass";

function createLinkPathDe(slugref, location, initState) {
  const { pathname } = location;
  const pathsplit = pathname.split("/");
  const pathCorrected =
    pathsplit[pathsplit.length - 1] === "" ? pathsplit.slice(0, -1) : pathsplit;

  console.log(slugref, location);

  // if not en in path return pathname
  if (pathCorrected[1] !== "en") return pathname;

  // /en => /
  if (pathCorrected.length === 2 && pathCorrected[1] === "en") return ``;

  // /en/x => /x
  if (pathCorrected.length === 3 && pathCorrected[1] === "en")
    return `${slugref}`;

  // /en/x/y => /x/y
  if (pathCorrected.length === 4 && pathCorrected[1] === "en")
    return `portfolio/${slugref}`;
  else return pathname;
}

function createLinkPathEn(slugref, location) {
  const { pathname } = location;
  const pathsplit = pathname.split("/");
  const pathCorrected =
    pathsplit[pathsplit.length - 1] === "" ? pathsplit.slice(0, -1) : pathsplit;

  console.log(slugref, location);

  // if en in path return pathname
  if (pathCorrected[1] === "en") return pathname;

  // / => /en
  if (pathCorrected.length === 2 && pathCorrected[1] === "") return `en`;
  if (pathCorrected.length === 1) return `en`;

  // /x => /en/x
  if (pathCorrected.length === 2 && pathCorrected[1] !== "")
    return `en/${slugref}`;

  // /x/y => /en/x/y
  if (pathCorrected.length === 3) return `en/portfolio/${slugref}`;
  else return pathname;
}

const TemplateWrapper = ({
  children,
  data: home,
  location,
  toggle,
  setToggle,
  slugref,
  relScrollHeight,
  color,
}) => {
  const { innerWidth } = useWindowDimensions();
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          datoCmsSite {
            globalSeo {
              siteName
            }
            faviconMetaTags {
              ...GatsbyDatoCmsFaviconMetaTags
            }
          }
          datoCmsHome {
            seoMetaTags {
              ...GatsbyDatoCmsSeoMetaTags
            }
          }
        }
      `}
      render={(data) => (
        <div>
          <header className="topnav___container index___flex">
            <HelmetDatoCms
              favicon={data.datoCmsSite.faviconMetaTags}
              seo={data.datoCmsHome.seoMetaTags}
            >
              <html lang={home.locale} />
            </HelmetDatoCms>
            <Link
              to={`/${home.locale !== "de" ? `${home.locale}` : ""}`}
              onClick={() => setToggle(false)}
            >
              <h1 className="font-regular top">{home.titel}</h1>
              <h2 className="font-thin top">{home.subtitel}</h2>
            </Link>
            <span className="index___spacer"></span>
            {innerWidth > 533 && (
              <div>
                <h3 className="font-thin">
                  <Link
                    className={
                      location.pathname.split("/")[1] === "en" ? "active" : ""
                    }
                    to={createLinkPathEn(slugref, location)}
                  >
                    english
                  </Link>
                </h3>
                <h3>&nbsp;/&nbsp;</h3>
                <h3 className="font-thin">
                  {console.log(location.pathname.split("/"))}
                  <Link
                    className={
                      location.pathname.split("/")[1] !== "en" ? "active" : ""
                    }
                    to={createLinkPathDe(slugref, location)}
                  >
                    deutsch
                  </Link>
                </h3>
              </div>
            )}
            {innerWidth < 533 && (
              <div>
                <h3 className="font-thin">
                  <Link
                    className={
                      location.pathname.split("/")[1] === "en" ? "active" : ""
                    }
                    to={createLinkPathEn(slugref, location)}
                  >
                    en
                  </Link>
                </h3>
                <h3>&nbsp;/&nbsp;</h3>
                <h3 className="font-thin">
                  <Link
                    className={
                      location.pathname.split("/")[1] !== "en" ? "active" : ""
                    }
                    to={createLinkPathDe(slugref, location)}
                  >
                    de
                  </Link>
                </h3>
              </div>
            )}
            {relScrollHeight !== 0 && (
              <div className="scrollIndicator-container">
                <div
                  className="scrollIndicator"
                  style={{
                    transform: `scaleX(${relScrollHeight})`,
                    background: color,
                  }}
                ></div>
              </div>
            )}
          </header>
          {children}
          <nav className="menu-nav" role="navigation">
            <input
              className="fixed"
              type="checkbox"
              onChange={() => setToggle(!toggle)}
            />
            <div className={`ham fixed ${toggle ? "open" : ""}`}>
              <span className="first"></span>
              <span className="second"></span>
              <span className="third"></span>
            </div>
            <div className="fixed" id="menuToggle"></div>
            <ul
              id="menu"
              className="font-regular fixed"
              style={
                !toggle
                  ? { transform: "translate(-100%, 0)" }
                  : { transform: "none" }
              }
            >
              <li>
                <Link
                  to={`/${home.locale !== "de" ? `${home.locale}` : ""}`}
                  onClick={() => setToggle(false)}
                >
                  {home.menu[0].home}
                </Link>
              </li>
              <li>
                <Link
                  to={`/${home.locale !== "de" ? `${home.locale}/` : ""}${
                    home.slug[0].aboutme
                  }`}
                  onClick={() => setToggle(false)}
                >
                  {home.menu[0].aboutMe}
                </Link>
              </li>
              <li>
                <Link
                  to={`/${home.locale !== "de" ? `${home.locale}/` : ""}${
                    home.slug[0].portfolio
                  }`}
                  onClick={() => setToggle(false)}
                >
                  {home.menu[0].portfolio}
                </Link>
              </li>
              <li>
                <Link
                  to={`/${home.locale !== "de" ? `${home.locale}/` : ""}${
                    home.slug[0].contact
                  }`}
                  onClick={() => setToggle(false)}
                >
                  {home.menu[0].contact}
                </Link>
              </li>
            </ul>
          </nav>
          <footer className="index___flex">
            {innerWidth > 533 && <h3>copyright 2020 niel.tech</h3>}
            {innerWidth < 533 && <h3>© 2020 niel.tech</h3>}
            <span className="index___spacer"></span>
            <SocialMediaLinks
              iconColor="rgba(0,0,0,1)"
              backgroundColor="rgba(91,139,151,0)"
              iconSize="0"
              roundness="50%"
              size="45"
            />
          </footer>
        </div>
      )}
    />
  );
};

TemplateWrapper.propTypes = {
  children: PropTypes.object,
};

export default TemplateWrapper;
/* eslint-enable jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid*/
