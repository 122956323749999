import React from "react";
import { SocialMediaIconsReact } from "social-media-icons-react";

import "../../styles/common/socialmedialinks.sass";

const SocialMediaLinks = (props) => {
  const {
    borderColor,
    borderWidth,
    borderStyle,
    iconColor,
    backgroundColor,
    iconSize,
    roundness,
    size,
  } = props;
  return (
    <div className="sml___center">
      <div className="sml___flex">
        <SocialMediaIconsReact
          borderColor={borderColor}
          borderWidth={borderWidth}
          borderStyle={borderStyle}
          iconColor={iconColor}
          backgroundColor={backgroundColor}
          iconSize={iconSize}
          roundness={roundness}
          size={size}
          icon="twitter"
          url="https://twitter.com"
        />
        <SocialMediaIconsReact
          borderColor={borderColor}
          borderWidth={borderWidth}
          borderStyle={borderStyle}
          iconColor={iconColor}
          backgroundColor={backgroundColor}
          iconSize={iconSize}
          roundness={roundness}
          size={size}
          icon="github"
          url="https://github.com/nielll"
        />
        <SocialMediaIconsReact
          borderColor={borderColor}
          borderWidth={borderWidth}
          borderStyle={borderStyle}
          iconColor={iconColor}
          backgroundColor={backgroundColor}
          iconSize={iconSize}
          roundness={roundness}
          size={size}
          icon="facebook"
          url="https://facebook.com"
        />
      </div>
    </div>
  );
};

export default SocialMediaLinks;
